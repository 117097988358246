import React, {useRef} from 'react';
import './Join.css';
import emailjs from '@emailjs/browser'

const Join = () => {
    const form = useRef()

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_fxu8r3q', 'template_o3y8r0x', form.current, '9jbMl0hYSi7XJCPj6')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };


  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className='stroke-text'>ESTÁ PREPARADO(A) </span>
          <br></br>
          <span>PARA AUMENTAR A PERFORMANCE </span>
        </div>
        <div>
          <span>DO SEU CORPO </span>
          <span className='stroke-text'>CONOSCO?</span>
        </div>
      </div>
      <div className="right-j">
        <form ref={form} className="email-container" onSubmit={sendEmail}>
          <input type="email" name="user_email" placeholder='Digite seu endereço de email' />
          <button className="btn btn-j">Cadastre-se agora!</button>
        </form>
      </div>
    </div>
  )
}

export default Join