import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

const Hero = () => {

    const transition = {type: 'spring', duration: 3}
    const mobile = window.innerWidth<=768 
    ? true
    : false;

  return (
    <div className="hero" id="home">

        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
            {/* The bast ad */}
            <div className="the-best-ad">
                <motion.div
                initial={{left: mobile? "165px":'238px'}}
                whileInView={{left: '8px'}}
                transition={{...transition, type: 'tween'}}
                ></motion.div>
                <span>O Melhor Club Fitness Da Cidade</span>
            </div>

            {/* Hero heading */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Construa </span>
                    <span>o Seu </span>
                </div>
                <div>
                    <span> Corpo Ideal</span>
                </div>
                <div>
                    <span>
                        Aqui vamos ajudá-lo(a) a moldar e construir seu corpo ideal e viver sua vida ao máximo
                    </span>
                </div>
            </div>
            {/* Figures */}
            <div className="figures">
                <div>
                    <span>
                        <NumberCounter start={80} end={140} delay="4" preFix="+" />
                    </span>
                    <span>personais especializados</span>
                </div>
                <div>
                    <span>
                    <NumberCounter start={800} end={978} delay="4" preFix="+" />
                    </span>
                    <span>membros incritos</span>
                </div>
                <div>
                    <span>
                    <NumberCounter start={20} end={50} delay="4" preFix="+" />
                    </span>
                    <span>programas fitness</span>
                </div>
            </div>

            {/* Hero buttons */}
            <div className="hero-buttons">
                <buttons className="btn">Comece Agora!</buttons>
                <buttons className="btn">Saiba Mais!</buttons>
            </div>
        </div>
        <div className="right-h">
            <button className="btn">Cadastre-se agora</button>
            
            <motion.div
                initial={{right: '-1rem'}}
                whileInView={{right: '4rem'}}
                transition={transition}
                className="heart-rate">
                <img src={Heart} alt="" />
                <span>Batimento cardíaco</span>
                <span>116 bpm</span>
            </motion.div>

            {/* Hero images */}
            <img src={hero_image} alt="" className='hero-image'/>
            <motion.img
                initial={{right: '11rem'}}
                whileInView={{right: '20rem'}}
                transition={transition}
                src={hero_image_back} alt="" className='hero-image-back'/>

            {/* Calories */}
            <motion.div 
                initial={{right: '37rem'}}
                whileInView={{right: '28rem'}}
                transition={transition}
                className="calories">
                <img src={Calories} alt=""/>
                <div>
                    <span>Queima calórica</span>
                    <span>220 kcal</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}

export default Hero