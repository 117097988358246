import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review: "Fiz a escolha certa ao escolher a Fitclub e ao escolher o plano e o programa certos. Já alcancei meu corpo ideal!",
    name: 'Jorge Braga',
    status : 'EMPREENDEDOR'
  },
  {
    image: image2,
    review: 'Fui ver a academia sem compromisso e me impressionei com o lugar. Recepção nota 10! Aparelhos de ponta e todo suporte que preciso estão sempre à disposição. Eu recomendo a FitClub',
    name: 'Matheus Felipe',
    status: 'COACH'
  },
  {
    image : image3,
    review:'Essa academia não deixa a desejar em nada. Climatizada, banheiros espaçosos e com diversos aparelhos, sem falar na equipe e no coach. Nota 1000! ',
    name: 'Bruno Costa',
    status: "CLIENTE"
  }
];
